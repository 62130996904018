import { useMemo } from 'react';
import { UseNavProps } from './types';

export const useNav = (props: UseNavProps) =>
  useMemo(() => {
    const { allCategories, categoryId, listingId, listingLink } = props;

    return [
      { key: listingId, label: 'ALL STORIES', link: { to: listingLink || '' }, isActive: false },
      ...allCategories?.nodes?.map(({ _id, name, slug }) => ({
        key: _id || '',
        label: name || '',
        link: { to: slug?.current || '' },
        isActive: _id === categoryId,
      })),
    ];
  }, [props]);
